import {graphql, StaticQuery} from "gatsby";
import _ from 'lodash';
import React from 'react';
import './leads.scss';
import '../../styles/shared.scss';
import {
    getPage,
    TypeLeadsLandingPage,
    TypeLeadsLandingQueryResult
} from "../../utils/queries/leads_landing_page";
import {
    PageHeaderCustomV2,
    PageHeaderCustomV2WhiteWide,
    PageHeaderStandard
} from "../../components/page-header/page-header";
import {PageTitle} from "../../components/title/title";
import AmazeeIoMetadata from "../../components/metadata";
import AmazeePage from '../../components/layout';
import usePreviewData from "../../utils/usePreviewData";
import RichTextRender from "../../utils/rich-text";
import {ResourceInteruptionWide} from "../../templates/resources/customers";
import Helmet from "react-helmet";
import {CustomerStandardCardOpenBlank, StandardCard} from "../../templates/resources/cards";
import {Resource} from "../../utils/queries/resources";
import {HomepageLogoCarousel} from '../../components/homepage-logos/carousel';

import Linked from "../../utils/linkedItem";
import {AmazeeIOButton} from "../../components/button/button";

const query = graphql`
{
  allPrismicLeadsLandingPage {
    edges {
      node {
        data {
          cta_button_text
          cta_text {
            text
            raw
            html
          }
          page_meta_description
          page_meta_thumbnail {
            url
            alt
          }
          hero_image {
            url
            alt
          }
          second_cta_button_text
          section_title1
          section_title2
          section_title3
          heading4
          section_subheading
          section_subheading1
          logo_section_text
          logos {
            logo {
              url
              alt
            }
          }
          subtitle {
            text
            raw
            html
          }
          title
          cta_link {
            document {
              ... on PrismicPageDefinitions {
                id
                data {
                  url
                  friendly_name
                  is_external
                }
              }
            }
          }
          button_link {
            document {
              ... on PrismicPageDefinitions {
                id
                data {
                  url
                  friendly_name
                  is_external
                }
              }
            }
          }
          second_cta_button_link {
            document {
              ... on PrismicPageDefinitions {
                id
                data {
                  url
                  is_external
                  friendly_name
                }
              }
            }
          }
          latest_webops_blog_post {
            document {
              ... on PrismicBlogPost {
                id
                data {
                  main_image {
                    url
                    alt
                  }
                  slug
                  title
                }
              }
            }
          }
          resources_group {
            resource_image {
              alt
              url
            }
            resource_intro
            resource_link {
              document {
                ... on PrismicPageDefinitions {
                  data {
                    url
                  }
                }
              }
            }
            resource_type
            resource_heading
          }
          case_studies_group {
            case_study {
              document {
                ... on PrismicResource {
                  id
                  data {
                    company_name
                    title
                    resource_type {
                      document {
                        ... on PrismicResourceType {
                          id
                          data {
                            type
                            display_in_filter_dropdown
                          }
                        }
                      }
                    }
                    description {
                      html
                      raw
                      text
                    }
                    case_study_content {
                      document {
                        ... on PrismicCaseStudy {
                          id
                          data {
                            slug
                          }
                        }
                      }
                    }
                    link {
                      document {
                        ... on PrismicCaseStudy {
                          id
                          data {
                            slug
                          }
                        }
                      }
                      type
                      link_type
                      url
                    }
                    image {
                      url
                      alt
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

const HeroSection: React.FC = ({data}: { data: TypeLeadsLandingPage }) => {
    const bgcStyle = {
        backgroundImage: `url('${data.hero_image.url}')`,
        backgroundPosition: `80% center`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `43%`,
    };

    return (
        <div className="hero-content">
        <div className="container image-background"  style={bgcStyle}>
        <div className="row justify-center mt-10">
        <div className="col-12 col-md-10">
            <div className="container h-full">
                <div className="row h-full">
                    <div className="col-content h-full">
                        <div className="flex flex-col h-full w-full justify-center content">
                            <h1>{data.title}</h1>
                            {RichTextRender(data.subtitle)}
                        </div>
                    </div>
                </div>
                </div>
                </div>
                </div>
            </div>
        </div>
    )
}


const LeadsLandingPage = ({
                                     data,
                                     location
                                 }: { location: Location, data: TypeLeadsLandingQueryResult }) => {
    const result = usePreviewData(getPage(data), 'allPrismicLeadsLandingPage', true) as TypeLeadsLandingPage;

    const renderableResourcesCaseStudies = result.case_studies_group.map((rawResource) => new Resource(rawResource.case_study.document.data));

    return (
        <AmazeePage location={location} id="leads">
            <div>
                <PageTitle title={result.title}/>
                <Helmet>
                    <link rel="stylesheet" href="/css/carousel-single.min.css"/>
                    <link rel="stylesheet" href="/css/carousel-multi.min.css"/>
                </Helmet>
                <AmazeeIoMetadata title={result.title} page_meta_description={result.page_meta_description}
                                  page_meta_thumbnail={result.page_meta_thumbnail} location={location}/>

                {/*}<PageHeaderStandard component={PageHeaderCustomV2WhiteWide} header={result.title} image={result.hero_image}
                                    subheader={result.subtitle} withSiteName={false} hasSectionDivider={false}/>
                */}
                <HeroSection data={result}/>

                <section id="quote">
                    <div className="container">
                        <div className="row flex justify-center">
                            <div className="col-12 col-lg-10 text-center">
                                <h3>{RichTextRender(result.section_title1)}</h3>
                            </div>
                            <div className="col-12 col-lg-6 text-center">
                              <a href={result.button_link.document.data.url} target="_blank"><AmazeeIOButton
                                classes="inverted inline-block">See more on Our Reviews Page</AmazeeIOButton></a>
                            </div>
                        </div>
                        <div className="row flex justify-center">
                            <div className="col-12 col-lg-9">
                                <div className="row">

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="logos" className="logos no-padding white">
                    <div className="section_header"><h3>{result.logo_section_text}</h3></div>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <HomepageLogoCarousel itemsForTablet={2} logos={result.logos}/>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="open_source_webops">
                    <div className="container">
                        <div className="row flex justify-center">
                            <div className="col-12 col-lg-7 text-center">
                            <h3>{result.section_title3}</h3>
                            <h6>{result.section_subheading}</h6>
                            </div>
                        </div>
                        <div className="row">
                            {renderableResourcesCaseStudies.map((resource, index) => resource.companyName ? <CustomerStandardCardOpenBlank
                                resource={resource}
                                key={index}/> : <StandardCard
                                resource={resource}
                                key={index}/>)}
                        </div>
                    </div>
                </section>

                <section id="how_to_use_webops">
                    <div className="container">
                        <div className="row flex justify-center">
                            <div className="col-12 col-lg-7 text-center">
                                <h3>{result.section_title2}</h3>
                                <h6>{result.section_subheading1}</h6>

                            </div>
                        </div>

                        <div className="row">
                          {result.resources_group.map((resourceItem, index) =>
                          <a key={index} target="_blank" href={resourceItem.resource_link.document.data.url} className="resource-card-link block col-12 col-md-4 ">
                              <div className="resource-card cursor-pointer relative">
                                  <div className="flex flex-col" style={{height: 600}}>
                                      <div className="w-full flex-shrink-0" style={{height: 250}}><img src={resourceItem.resource_image.url}/>
                                      </div>
                                      <div className="w-full content">
                                          <div className="content-type">{resourceItem.resource_type}</div>
                                          <div className="content-title">{resourceItem.resource_heading}</div>
                                          <div className="content-description line-clamp">{resourceItem.resource_intro}</div>
                                      </div>
                                  </div>
                              </div>
                          </a>)}
                      </div>
                    </div>
                </section>

                <section id="latest_blog_webops_post">
                    <div className="container">
                        <div className="row flex justify-center">
                            <div className="col-12 col-lg-10 text-center">
                                <h3>{result.heading4}</h3>
                            </div>
                        </div>

                        <div className="row bg-white">
                          <div className="col-12 col-lg-6 text-center">
                            <img src={result.latest_webops_blog_post.document.data.main_image.url} />
                          </div>
                          <div className="col-12 col-lg-6 text-left">
                            <div className="post_inner p-12">
                              <div className="blog-blue mb-4">Blog</div>
                              <h3>{result.latest_webops_blog_post.document.data.title}</h3>
                              <a target="_blank" className="btn-boldish amazeeio-button rounded-full px-4 py-2 inverted inline-block" href={`https://amazee.io/blog/post/${result.latest_webops_blog_post.document.data.slug}/`}>Read the article</a>
                            </div>
                          </div>
                        </div>
                    </div>
                </section>

                <ResourceInteruptionWide cta_text={RichTextRender(result.cta_text)} cta_button_text={result.cta_button_text}
                                     second_cta_button_text={result.second_cta_button_text} cta_link={result.cta_link}
                                     second_cta_button_link={result.second_cta_button_link}/>
            </div>
        </AmazeePage>
    );
};

const StaticLeadsLandingPage: React.FC<{ location: Location }> = ({location}) => {
    return (
        <StaticQuery
            query={query}
            render={((data) => <LeadsLandingPage location={location} data={data}/>)}
        />
    )
}
export default StaticLeadsLandingPage;
