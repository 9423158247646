import {PrismicImage, PrismicPageDefinition, PrismicRichText} from "./types";
import {PrismicRawResourceData, PrismicRawResourceEdge, Resource} from "./resources";
import {TypeHomepageV2Quote} from "./homepagev2";

export type TypeLeadsLandingLogos = {
    logo: PrismicImage;
  };

  export type TypeLeadsLandingResources = {
    resource_image: PrismicImage;
    resource_type: string;
    resource_heading: string;
    resource_intro: string;
    resource_link: PrismicPageDefinition
    };

export type TypeLeadsLandingPage = {
  title: string;
  subtitle: PrismicRichText;

  hero_image: PrismicImage;
  section_title: string;
  section_subheading: string;
  section_title1: string;
  button_link: PrismicPageDefinition;
  section_subheading1: string;
  section_title2: string;
  section_title3: string;
  heading4: string;
  cta_text: PrismicRichText;
  cta_button_text: string;
  cta_link: PrismicPageDefinition;
  second_cta_button_text: string;
  second_cta_button_link: PrismicPageDefinition;
  logo_section_text: string;
  logos: Array<TypeLeadsLandingLogos>;

  case_studies_group: Array<{
    case_study: {
      document: {
        data: PrismicRawResourceData;
      };
    };
  }>

  resources_group: Array<TypeLeadsLandingResources>

  latest_webops_blog_post: {
    document: {
      data: PrismicRawResourceData;
    };
  };

  page_meta_title: string;
  page_meta_thumbnail: PrismicImage;
  page_meta_description: string;
};

export type TypeLeadsLandingQueryResult = {
  allPrismicLeadsLandingPage: {
    edges: Array<{
      node: { data: TypeLeadsLandingPage };
    }>;
  };
};

export const getPage = (data: TypeLeadsLandingQueryResult): TypeLeadsLandingPage => {
  return data.allPrismicLeadsLandingPage.edges[0].node.data;
}
